import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Layout = ({ location, children }) => {
	const rootPath = `${__PATH_PREFIX__}/`;
	const isRootPath = location.pathname === rootPath;

	return (
		<div className="global-wrapper" data-is-root-path={isRootPath}>
			<header>
				<h1>
					<Link to="/">
						<StaticImage
							className="logo-image"
							layout="fixed"
							formats={["auto", "webp", "avif"]}
							src="../images/logo.png"
							width={600}
							height={95}
							quality={100}
							alt="logo画像"
						/>
					</Link>
				</h1>
				<nav>
					<ul>
						<li>
							<Link className="zoom-link" to="/about">
								About
							</Link>
						</li>
						<li>
							<Link className="zoom-link" to="/works" state={{ category: "Works" }}>
								Works
							</Link>
						</li>
						<li>
							<Link className="zoom-link" to="/contact">
								Contact
							</Link>
						</li>
					</ul>
				</nav>
			</header>
			<main>{children}</main>
			<footer>
				<div>
					<Link className="zoom-link" to="/termsOfService">
						利用規約
					</Link>
					{" / "}
					<Link className="zoom-link" to="/privacyPolicy">
						プライバシーポリシー
					</Link>
				</div>
				<hr />© {new Date().getFullYear()}, GreenStudio All Rights Reserved.
			</footer>
		</div>
	);
};

export default Layout;
